import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IAiAnswer, IAiAnswerResponse } from '../types/curation'

export const fetchAiCurationAnswer = async (curationId: number) => {
  const res = await axios.get<IAiAnswerResponse[]>(
    `${window.apiEndpoint}/sqna/v1/ai-curation/${curationId}/answers`,
  )

  return convertCaseList(res.data, ConvertType.CAMEL) as IAiAnswer[]
}

export const useAiCurationAnswerQuery = (curationId: number) => {
  return useQuery({
    queryKey: ['aiCurationAnswer', curationId],
    queryFn: () => fetchAiCurationAnswer(curationId),
    staleTime: Infinity,
    cacheTime: 60 * 60 * 1000,
  })
}
