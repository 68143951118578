import { IChatList } from '.'

interface IChatItemProps {
  isUser: boolean
  chat: IChatList
  onClickAnswer: (chatItem: IChatList) => void
}

const ChatItem = ({ isUser, chat, onClickAnswer }: IChatItemProps) => {
  return (
    <div className="flex gap-2 lg:gap-4">
      <div className="w-6 shrink-0 lg:w-10">
        <img
          src={`https://d35ai18pny966l.cloudfront.net/course/home/${
            isUser ? 'user_avatar' : 'lion_avatar'
          }.svg`}
          alt={isUser ? '나' : '멋쟁이사자처럼 AI'}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-1 lg:gap-1.5">
          <p className="text-sm font-semibold lg:text-lg">{isUser ? '나' : '멋쟁이사자처럼 AI'}</p>
          <p className="whitespace-pre-line text-sm lg:text-base">
            {isUser ? chat.question : chat.answer}
          </p>
        </div>
        {!isUser && chat.childQuestions && (
          <ul className="mt-3 flex flex-wrap gap-2 lg:mt-4 lg:gap-2.5">
            {chat.childQuestions?.map(question => {
              const isAnswer = question.type === 'answer'
              const isChoiceAnswer = chat.selectAnswer === question.question
              return (
                <li
                  className="text-sm lg:text-base"
                  key={question.id}
                  onClick={() =>
                    onClickAnswer({
                      ...question,
                      answer: null,
                      childQuestions: chat.childQuestions,
                      selectAnswer: question.question,
                    })
                  }>
                  {isAnswer ? (
                    <button
                      disabled
                      className={`${
                        isChoiceAnswer ? 'text-label-neutral' : 'text-label-disabled'
                      } rounded-full bg-gray-100 px-5 py-2 font-medium lg:px-4 lg:py-2.5`}>
                      “{question.question}”
                    </button>
                  ) : (
                    <button
                      disabled
                      className="rounded-lg bg-primary px-4 py-2.5 font-semibold text-white">
                      {question.question}
                    </button>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ChatItem
