import { ArrowRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link'

interface IKDTTextBannerItemProps {
  textBanner: {
    category: string
    title: string
    goToTitle: string
    url: string
  }
}

const KDTTextBannerItem = ({ textBanner }: IKDTTextBannerItemProps) => {
  const isTablet = useMediaQuery('(max-width:1024px)')
  return (
    <Link href={textBanner.url}>
      <a className="w-full" target="_blank">
        <div className="flex w-full flex-col flex-nowrap justify-between gap-2 rounded-xl border border-normal bg-gray-50 px-6 py-4 md:flex-row md:items-center md:gap-0 lg:px-10 lg:py-5">
          <div className="flex items-center gap-4">
            {!isTablet && (
              <span className="rounded-md bg-primary px-2.5 py-1 font-semibold text-white">
                {textBanner.category}
              </span>
            )}
            <p className="whitespace-pre-line text-base font-semibold lg:whitespace-normal lg:text-xl">
              {textBanner.title}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-sm text-primary-normal lg:text-lg lg:font-semibold">
              {textBanner.goToTitle}
            </p>
            <ArrowRightIcon className="w-4 text-primary-normal lg:w-[18px]" />
          </div>
        </div>
      </a>
    </Link>
  )
}

export default KDTTextBannerItem
