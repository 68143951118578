import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IKlassCategory } from '../types'

export const fetchKlassCategoryList = async (type: string) => {
  const res = await axios.get(
    `${window.apiEndpoint}/course/v1/category?filters=type:${type}&sort=order:asc`,
  )
  return convertCaseList(res.data, ConvertType.CAMEL) as IKlassCategory[]
}

export const useKlassCategoryListQuery = (type: string) => {
  return useQuery({
    queryKey: ['category', type],
    queryFn: () => fetchKlassCategoryList(type),
  })
}
