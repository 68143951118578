import { Popover } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { MutableRefObject } from 'react'

import styles from './HomeTooltip.module.scss'

interface IHomeTooltipProps {
  wrapperElementRef: MutableRefObject<HTMLDivElement | null>
  onCloseTooltip: () => void
}

const HomeTooltip = ({ wrapperElementRef, onCloseTooltip }: IHomeTooltipProps) => {
  return (
    <div ref={wrapperElementRef} className={`${styles.TooltipMotion} z-75`}>
      <Popover className="relative flex w-full justify-center">
        <div className={`${styles.Tooltip}`} />
        <svg
          className={`cursor-default ${styles.TooltipArrow}`}
          width="20"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.26795 0.999997C6.03775 -0.333336 7.96225 -0.333333 8.73205 1L13.0622 8.5C13.832 9.83334 12.8697 11.5 11.3301 11.5H2.66987C1.13027 11.5 0.168022 9.83333 0.937822 8.5L5.26795 0.999997Z"
            fill="#262626"
          />
        </svg>
        <Popover.Panel static className={`absolute z-10 cursor-default ${styles.Tooltip}`}>
          <div className="flex w-[195px] items-center justify-between rounded-full bg-gray-800 px-4 py-2 text-white md:w-[248px]">
            <p className="text-xs md:text-base">관심 있는 질문을 선택해보세요</p>
            <button onClick={onCloseTooltip}>
              <XIcon className="w-4 md:w-5" />
            </button>
          </div>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default HomeTooltip
