import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IAiCuration, IAiCurationResponse } from '../types/curation'

export const fetchAiCurationList = async (parentId: number) => {
  const res = await axios.get<IAiCurationResponse[]>(
    `${window.apiEndpoint}/sqna/v1/ai-curations?parent_id=${parentId}`,
  )

  return convertCaseList(res.data, ConvertType.CAMEL) as IAiCuration[]
}

export const useAiCurationListQuery = (parentId: number) => {
  return useQuery({
    queryKey: ['aiCurationList', parentId],
    queryFn: () => fetchAiCurationList(parentId),
    staleTime: Infinity,
    cacheTime: 60 * 60 * 1000,
  })
}
