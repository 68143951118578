import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface ITypingAnswerProps {
  longText: string
  setTypingComplete: Dispatch<SetStateAction<boolean>>
}

const TypingAnswer = ({ longText, setTypingComplete }: ITypingAnswerProps) => {
  const [text, setText] = useState('') // 현재까지 타이핑된 텍스트 상태
  const [index, setIndex] = useState(0) // 현재까지 출력된 단어의 인덱스

  useEffect(() => {
    const words = longText.split(' ') // 문장을 공백 기준으로 단어 배열로 분리

    const typeText = () => {
      // 세 단어씩 잘라서 출력
      const randomChunk = _.shuffle([4, 5, 6, 7])[0]
      const textChunk = words.slice(index, index + randomChunk).join(' ')
      setText(prevText => prevText + ' ' + textChunk) // 기존 텍스트에 추가

      setIndex(prevIndex => prevIndex + randomChunk) // 다음 인덱스로 이동
    }

    if (index >= words.length) {
      setTypingComplete(true)
    } else {
      const timer = setTimeout(typeText, 100)
      return () => clearTimeout(timer) // 컴포넌트 언마운트 시 타이머 정리
    }
  }, [index])

  return <div>{text}</div>
}

export default TypingAnswer
