import { IKlassCategory } from 'features/catalog/types'
import { useRouter } from 'next/router'

interface ICategoryItemProps {
  category: IKlassCategory
}

const CategoryItem = ({ category }: ICategoryItemProps) => {
  const router = useRouter()
  const goToPage = () => {
    return category.link ? router.push(category.link) : null
  }
  return (
    <li
      className="relative flex w-18 cursor-pointer flex-col items-center justify-center gap-3 md:w-30"
      onClick={goToPage}>
      <div className="h-[60px] w-[60px] md:h-18 md:w-18 lg:h-[84px] lg:w-[84px]">
        {category.image && (
          <img
            src={category.image}
            alt={category.name}
            className="h-full w-full rounded-[20px] lg:rounded-3xl"
          />
        )}
        {category.badge && (
          <div className="absolute -right-1 -top-3 flex shrink-0 items-center justify-center rounded-full bg-primary px-2.5 py-1 text-xs font-semibold text-white md:-top-2 md:right-2 md:py-0.5 md:text-sm lg:right-1">
            {category.badge}
          </div>
        )}
      </div>
      <span className="text-xs md:text-base">{category.name}</span>
    </li>
  )
}

export default CategoryItem
