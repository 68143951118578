import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import _ from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import styles from './HomeCTACard.module.scss'

interface IHomeCTACardProps {
  title: string
  category: string
  url: string
  bgImg: IBgImgProps
}
interface IBgImgProps {
  pc: string
  tablet: string | null
  mobile: string | null
  laptop: string | null
}

const HomeCTACard = ({ title, category, url, bgImg }: IHomeCTACardProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)')
  const isLaptop = useMediaQuery('(min-width:1024px) and (max-width:1279px)')
  const { pc, mobile, tablet, laptop } = bgImg
  const renderBgImg = () => {
    if (isMobile && !_.isNull(mobile)) return mobile
    else if (isTablet && !_.isNull(tablet)) return tablet
    else if (isLaptop && !_.isNull(laptop)) return laptop
    else return pc
  }
  return (
    <Link href={url} as={url}>
      <a className={`${styles.EducationCard} relative bg-[#FCF4EE] px-4 py-5 md:p-8 lg:p-10`}>
        <div className="absolute left-0 top-0 z-0 h-full w-full">
          <Image
            src={renderBgImg()}
            alt={title}
            layout="fill"
            objectFit="cover"
            className="bg-right-bottom"
          />
        </div>
        <div className="relative z-10 flex h-full flex-col justify-between">
          <div className="flex flex-col gap-2 md:gap-4">
            <p className="whitespace-pre-wrap text-sm md:text-base">{category}</p>
            <h4 className="whitespace-pre-line text-lg font-semibold lg:text-2xl">{title}</h4>
          </div>
          <button className="mt-4 inline-flex items-center font-semibold text-label-alternative">
            <span className="text-sm font-medium lg:font-semibold">자세히 보기</span>
            <ArrowRightIcon width={16} className="ml-1 md:ml-2" />
          </button>
        </div>
      </a>
    </Link>
  )
}

export default HomeCTACard
