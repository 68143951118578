import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useMediaQuery } from '@mantine/hooks'
import ChatBotLoading from 'designs/Loading/ChatBotLoading'
import { useAiCurationAnswerQuery } from 'features/showroom/queries/useAiCurationAnswerQuery'
import useAiCurationHistories from 'features/showroom/queries/useAiCurationHistories'
import { useAiCurationListQuery } from 'features/showroom/queries/useAiCurationListQuery'
import { IAiCuration } from 'features/showroom/types/curation'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { IProfile } from 'stores/auth'

import styles from './AIModal.module.scss'
import ChatItem from './ChatItem'
import TypingAnswer from './TypingAnswer'

export interface IChatList {
  id: number
  type: string
  answer: string | null
  question: string
  link: string
  linkNavigationType: string
  childQuestions: IAiCuration[] | null
  selectAnswer: string
}
interface IAIModalProps {
  isOpen: boolean
  handleClose: () => void
  uuid: string
  profile: IProfile | null
}

const AIModal = ({ isOpen, handleClose, uuid, profile }: IAIModalProps) => {
  const containerRef = useRef(null)
  const focusRef = useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery('(max-width:1023px)')
  const router = useRouter()
  const [isDeferred, setIsDeferred] = useState(false)
  const [isBeforeAnswer, setIsBeforeAnswer] = useState(true)
  const [chatIndex, setChatIndex] = useState(0)
  const [chatList, setChatList] = useState<IChatList[]>([])
  const [isDelayAnswer, setIsDelayAnswer] = useState(true)
  const [typingComplete, setTypingComplete] = useState(false)

  // 최초 질문 리스트
  const { data: AICurationItem } = useAiCurationListQuery(0)
  const { data: AICurationAnswer } = useAiCurationAnswerQuery(chatIndex)
  const curationMutate = useAiCurationHistories()

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [typingComplete])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsDeferred(true)
    }, 1500)
    return () => clearTimeout(timeoutId)
  }, [])

  const onClickFirstAnswer = useCallback(
    (chatItem: IChatList) => {
      const historyData = {
        uuid: uuid,
        userId: profile ? profile.id : null,
        aiCurationId: chatItem.id,
        question: chatItem.question,
        depth: 1,
        link: chatItem.link,
      }
      curationMutate.mutate(historyData)

      setIsBeforeAnswer(false)
      setChatIndex(chatItem.id)

      setTimeout(() => {
        setIsDelayAnswer(false)
      }, 2000)
      setChatList(prev => {
        return [
          ...prev,
          {
            ...chatItem,
          },
        ]
      })
    },
    [isDelayAnswer],
  )

  // 챗봇 딜레이 효과
  useEffect(() => {
    setIsDelayAnswer(true)
    const timeoutId = setTimeout(() => {
      setIsDelayAnswer(false)
    }, 1500)
    return () => clearTimeout(timeoutId)
  }, [chatIndex, AICurationAnswer])

  const onClickAnswer = (chatItem: IChatList[]) => {
    if (chatItem[0].childQuestions) {
      const historyData = {
        uuid: uuid,
        userId: profile ? profile.id : null,
        aiCurationId: chatItem[0].childQuestions[0].id,
        question: chatItem[0].childQuestions[0].question,
        depth: chatItem[0].childQuestions[0].depth,
        link: chatItem[0].childQuestions[0].link,
      }
      curationMutate.mutate(historyData)
    }

    const isLast = chatItem[0].childQuestions?.every(el => el.type === 'link')

    if (chatItem[0].childQuestions && !isLast) {
      setChatIndex(chatItem[0].id)
      setChatList(prev => {
        return [...prev, ...chatItem]
      })
    }
  }

  const onClickReset = () => {
    setIsBeforeAnswer(true)
    setIsDelayAnswer(true)
    setChatList([])
  }

  const onClickLink = (linkType: string, url: string) => {
    return linkType === 'move'
      ? router.push(url)
      : window.open(url, '_blank', 'noopener, noreferrer')
  }

  return (
    <Transition
      show={isOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100">
      <Dialog
        open={isOpen}
        onClose={handleClose}
        initialFocus={containerRef}
        className={`fixed inset-0 z-200 ${styles.ModalContainer}`}>
        <div
          className="flex h-full items-center justify-center"
          onMouseDown={e => e.stopPropagation()}>
          <Dialog.Overlay
            className={`absolute left-0 top-0 z-40 h-screen w-screen overflow-hidden ${styles.BgOpacity55} ${styles.heightfill}`}
          />
          <div
            ref={containerRef}
            className={`${styles.modalWrapper} ${
              isMobile ? (isDeferred ? 'h-full w-full' : 'h-[400px] w-[328px] rounded-[24px]') : ''
            } shadow-[14px_27px_45px_4px_rgba(112, 144, 176, 0.20)] z-50 overflow-y-scroll bg-white transition-all duration-1000 lg:overflow-hidden lg:rounded-3xl`}>
            <div
              className={` ${
                isDeferred
                  ? 'lg:h-[698px] lg:w-[1000px] lg:max-w-[1000px]'
                  : 'h-full w-full items-center justify-center lg:h-[352px] lg:w-[582px]'
              } flex flex-col overflow-y-auto transition-all duration-1000`}>
              <div className="relative w-full">
                {isDeferred && (
                  <div className="fixed left-0 top-0 flex w-full justify-end bg-white px-4 py-2.5 lg:sticky lg:px-8 lg:pt-10">
                    <div className="flex items-center gap-3 bg-white lg:right-6 lg:top-6 lg:gap-5">
                      {!isBeforeAnswer && (
                        <button
                          className="rounded-full border border-normal px-4 py-2 text-sm font-semibold"
                          onClick={onClickReset}>
                          새 대화
                        </button>
                      )}
                      <button onClick={handleClose}>
                        <XIcon className="w-7 text-label-neutral" />
                      </button>
                    </div>
                  </div>
                )}
                {isBeforeAnswer ? (
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className={`${
                        isDeferred ? 'mt-16 lg:mt-[80px]' : styles.section1_mainlogo_pulse
                      } relative rounded-full`}>
                      <img
                        className="h-20 w-20 rounded-full lg:h-[84px] lg:w-[84px]"
                        src={'https://d35ai18pny966l.cloudfront.net/course/home/lion_avatar.svg'}
                        alt="[멋쟁이사자처럼]"
                      />
                    </div>
                    {isDeferred && (
                      <div className="mt-3 flex flex-col items-center justify-center lg:mt-18">
                        <p className="text-2xl font-bold lg:text-3xl">멋쟁이사자처럼 AI</p>
                        <p className="mt-2 text-center text-label-neutral lg:mt-4 lg:text-lg">
                          안녕하세요, 멋쟁이사자처럼 AI 입니다.
                          <br className="block md:hidden lg:block" />
                          {!isMobile && '당신의 관심사에 대해 알려주세요.'} 연구하고 통찰력있게
                          안내해드릴게요.
                        </p>
                        <ul className="mx-auto mt-6 flex flex-col flex-wrap items-center justify-center gap-2.5 px-4 md:mt-7 md:px-0 lg:mt-13 lg:w-[880px] lg:flex-row">
                          {AICurationItem &&
                            AICurationItem.map(curation => (
                              <li
                                className="cursor-pointer rounded-full bg-gray-100 px-4 py-2.5 text-center font-medium text-label-neutral transition-colors hover:bg-yellow-450 hover:text-white"
                                key={curation.id}
                                onClick={() =>
                                  onClickFirstAnswer({
                                    ...curation,
                                    answer: null,
                                    childQuestions: null,
                                    selectAnswer: curation.question,
                                  })
                                }>
                                “{curation.question}“
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <section
                    ref={focusRef}
                    className="flex flex-col gap-7 px-4 py-14 pt-20 lg:mt-3 lg:px-8 lg:pb-14 lg:pt-0">
                    {chatList.map((chat, idx) => {
                      const isUser = idx % 2 === 0
                      return (
                        <ChatItem
                          key={chat.answer}
                          chat={chat}
                          isUser={isUser}
                          onClickAnswer={() => onClickAnswer}
                        />
                      )
                    })}
                    {/* 멋사 ai */}
                    <div className="flex gap-2 lg:gap-4">
                      <div className="w-6 shrink-0 lg:w-10">
                        <img
                          src="https://d35ai18pny966l.cloudfront.net/course/home/lion_avatar.svg"
                          alt="멋쟁이사자처럼"
                        />
                      </div>
                      {AICurationAnswer && (
                        <div className="flex flex-col gap-1 lg:gap-1.5">
                          <p className="text-sm font-semibold lg:text-lg">멋쟁이사자처럼 AI</p>
                          {isDelayAnswer ? (
                            <div className="flex h-7 w-12 items-center justify-center rounded-full bg-black lg:h-10 lg:w-[68px]">
                              <ChatBotLoading />
                            </div>
                          ) : (
                            <>
                              <p className="whitespace-pre-line text-sm lg:text-base">
                                {/* {AICurationAnswer[0].answer} */}
                                <TypingAnswer
                                  longText={AICurationAnswer[0].answer}
                                  setTypingComplete={setTypingComplete}
                                />
                              </p>
                              {typingComplete && (
                                <ul className="mt-3 flex flex-wrap gap-2 lg:mt-4 lg:gap-2.5">
                                  {AICurationAnswer[0].childQuestions.map(question => {
                                    const isAnswer = question.type === 'answer'
                                    return (
                                      <li
                                        className="cursor-pointer text-sm lg:text-base"
                                        key={question.id}
                                        onClick={() => {
                                          onClickAnswer([
                                            {
                                              ...question,
                                              answer: AICurationAnswer[0].answer,
                                              childQuestions: AICurationAnswer[0].childQuestions,
                                              selectAnswer: question.question,
                                            },
                                            {
                                              ...question,
                                              answer: question.question,
                                              childQuestions: null,
                                              selectAnswer: question.question,
                                            },
                                          ])
                                          setTypingComplete(false)
                                        }}>
                                        {isAnswer ? (
                                          <button className="rounded-full bg-gray-100 px-5 py-2 font-medium text-label-neutral transition-colors hover:bg-yellow-450 hover:text-white lg:px-4 lg:py-2.5">
                                            “{question.question}”
                                          </button>
                                        ) : (
                                          <button
                                            className="rounded-lg bg-primary px-4 py-2.5 font-semibold text-white"
                                            onClick={() =>
                                              onClickLink(
                                                question.linkNavigationType,
                                                question.link,
                                              )
                                            }>
                                            {question.question}
                                          </button>
                                        )}
                                      </li>
                                    )
                                  })}
                                </ul>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AIModal
