import { ChevronRightIcon } from '@heroicons/react/outline'
import HomeCTACard from 'components/HomeCTACard'
import KDTKlassCard from 'components/KDTKlassCard'
import KdcSchoolSkeleton from 'components/Skeleton/KdcSchoolSkeleton'
import KdtSchoolSkeleton from 'components/Skeleton/KdtSchoolSkeleton'
import CatalogKlassCard from 'features/catalog/components/CatalogKlassCard'
import { useKlassCategoryListQuery } from 'features/catalog/queries/useKlassCategoryListQuery'
import { IKlass } from 'features/klass/types'
import AIModal from 'features/showroom/components/AIModal'
import CategoryItem from 'features/showroom/components/CategoryItem'
import HomeTooltip from 'features/showroom/components/HomeTooltip'
import KDCContentList from 'features/showroom/components/KDCContentList'
import KDTTextBanner from 'features/showroom/components/KDTTextBanner'
import { useShowroomQuery } from 'features/showroom/queries/useShowroomQuery'
import { sortedList } from 'features/showroom/utils'
import localforage from 'localforage'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from 'shared/contexts/Auth'
import Typewriter from 'typewriter-effect'
import ChannelTalk from 'utils/channeltalk'
import { v4 as uuid_v4 } from 'uuid'

import homeCourseCategoryList from '../mocks/homeCourseCategoryList.json'
import homeCTACardList from '../mocks/homeCTACardList.json'
import WithHeader from '../shared/layout/WithHeader'
import styles from './Home.module.scss'

const Home = () => {
  const [isDeferred, setIsDeferred] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOpenTooltip, setIsOpenTooltip] = useState(true)
  const tooltipContainerRef = useRef(null)
  const popularCateRef = useRef<(HTMLLIElement | null)[]>([])
  const [courseCategory, setCourseCategory] = useState('popular')
  const { data: showroomKDTList, isLoading: isLoadingKDT } = useShowroomQuery('home', 'kdt')
  const { data: showroomKDCList, isLoading: isLoadingKDC } = useShowroomQuery('home', 'kdc')
  const { data: showroomCategoryList } = useKlassCategoryListQuery('main')
  const { data: showroomCourseList } = useShowroomQuery('content_area', courseCategory)

  const { profile } = useAuth()
  const channelTalk = new ChannelTalk()

  const toggleChannelTalk = async () => {
    try {
      const isShowMessenger = await localforage.getItem('isShowMessenger')
      if (isShowMessenger === 'true') {
        window.ChannelIO('showMessenger')
      } else {
        window.ChannelIO('hideMessenger')
      }
    } catch (e) {
      console.error(e)
    }
  }

  const uuid = useMemo(() => {
    const getUuid = localStorage.getItem('uuid')
    if (getUuid) {
      return getUuid
    } else {
      const id = uuid_v4()
      localStorage.setItem('uuid', id)
      return id
    }
  }, [])

  const clearIsShow = async () => {
    await localforage.removeItem('isShowMessenger')
  }
  useEffect(() => {
    void toggleChannelTalk()

    return () => {
      void clearIsShow()
    }
  }, [])

  const onClickAiInput = () => {
    channelTalk.hide()
    setIsModalOpen(true)
  }

  const onCloseModal = () => {
    channelTalk.show()
    setIsModalOpen(false)
  }
  const onCloseTooltip = () => {
    const homeTooltip = {
      value: 'homeTooltip',
      expiry: Date.now() + 86400, //24 hour
    }
    localStorage.setItem('homeTooltipExpire', JSON.stringify(homeTooltip))
    setIsOpenTooltip(false)
  }
  const getKdtPlusApplyList = useMemo(() => {
    let kdtPlusList: IKlass[] = []
    const result: IKlass[] = []
    if (showroomKDTList) {
      kdtPlusList = showroomKDTList[0].courses.filter(
        klass => klass.id.includes('frontendplus') || klass.id.includes('backendplus'),
      )
    }
    kdtPlusList = sortedList(kdtPlusList, 'apply')
    kdtPlusList.forEach(v => {
      if (v.id.includes('frontend')) {
        result.unshift(v)
      }
      if (v.id.includes('backend')) {
        result.push(v)
      }
    })
    return result
  }, [showroomKDTList])

  const kdtShowroomList = useMemo(() => {
    if (showroomKDTList) {
      return [
        ...sortedList(
          showroomKDTList[0].courses.filter(
            klass => !klass.id.includes('frontendplus') && !klass.id.includes('backendplus'),
          ),
          'apply',
        ),
        ...getKdtPlusApplyList,
        ...sortedList(showroomKDTList[0].courses, 'early'),
      ].slice(0, 4)
    }
  }, [getKdtPlusApplyList, showroomKDTList])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsDeferred(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const getTooltipExpiry = localStorage.getItem('homeTooltipExpire')
    const now = new Date()
    if (getTooltipExpiry) {
      const expiryData = JSON.parse(getTooltipExpiry)
      setIsOpenTooltip(false)
      if (now.getTime() > expiryData.expiry) {
        localStorage.removeItem('homeTooltipExpire')
        setIsOpenTooltip(true)
      }
    }
  }, [])

  const onClickPopularCategory = (cateId: string, index: number) => {
    const selectCate = popularCateRef.current[index]
    if (selectCate) {
      selectCate.parentElement?.scrollTo({
        behavior: 'smooth',
        left: selectCate.offsetLeft - 100,
      })
      setCourseCategory(cateId)
    }
  }

  return (
    <WithHeader>
      <main className={`${isModalOpen ? 'overflow-hidden' : ''} min-w-[360px]`}>
        {/* Hero card */}
        {/* {isLoadingBanner && isDeferred ? (
          <MainBannerSkeleton />
        ) : (
          bannerList && <Banner bannerList={bannerList} />
        )} */}
        <section className="w-full bg-gradient-to-b from-[#FEF5EF] from-70% to-[#fff] px-4 md:px-0">
          <div className="flex flex-col items-center justify-center text-center text-2xl font-bold leading-tight md:text-[32px] lg:text-[42px]">
            <h2 className="mt-10 text-primary-normal md:mt-12 lg:mt-22">멋쟁이사자처럼과 함께</h2>
            <h2>맞춤형 성장으로 목표를 이뤄보세요.</h2>
          </div>
          <p className="mt-2 text-center text-base text-label-neutral md:mt-3 md:text-lg lg:mt-4">
            관심사에 대해 알려주세요. 저는 연구하고,
            <br className="block lg:hidden" /> 정보를 모아 통찰력 있는 답변을 드릴게요.
          </p>
          <div className="relative mx-auto mt-6 w-full cursor-pointer rounded-full border-2 border-primary bg-white px-4 py-4 shadow-[0px_10px_66px_0px_rgba(189,97,27,0.10)] md:w-[480px] md:px-5 lg:mt-7 lg:w-[800px] lg:px-6">
            <div className="relative" onClick={onClickAiInput}>
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2 lg:gap-3">
                  <img
                    src="https://d35ai18pny966l.cloudfront.net/course/home/icon_AI.png"
                    alt="ai icon"
                    className="w-[51px] lg:w-[61px]"
                  />
                  <div className="bg-gradient-to-r from-[#FF7710] to-[#FF9A2C] bg-clip-text text-lg font-semibold text-transparent lg:text-xl">
                    <Typewriter
                      options={{
                        strings: [
                          '무료로 수강 가능한가요?',
                          '개발자로 취업하고 싶어요',
                          '업무를 더 잘하고 싶어요',
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <img
                    src="https://d35ai18pny966l.cloudfront.net/course/home/gradient_chevron_right.png"
                    alt="gradient_chevron_right"
                  />
                </div>
              </div>
            </div>
            {isOpenTooltip && (
              <HomeTooltip
                wrapperElementRef={tooltipContainerRef}
                onCloseTooltip={onCloseTooltip}
              />
            )}
          </div>
        </section>
        <ul className="mt-15 flex max-w-7xl flex-wrap items-center justify-center gap-x-3 gap-y-5 px-4 md:mx-auto md:mt-16 md:gap-5 md:px-6 lg:mt-22">
          {showroomCategoryList &&
            showroomCategoryList.map(category => (
              <CategoryItem category={category} key={category.id} />
            ))}
        </ul>
        <div className="mx-4 flex max-w-7xl flex-col gap-12 px-0 md:mx-auto md:gap-24 md:px-6 lg:gap-[120px]">
          <section className="mt-10 md:mt-12 lg:mt-20">
            <div className="flex flex-col gap-2">
              <h3 className="text-2xl font-bold md:text-xl lg:text-[32px]">실시간 인기있는 강의</h3>
              <span className="text-sm text-label-neutral md:text-base">
                최근 많은 수강생들이 선택한 인기 강의를 확인해보세요.
              </span>
            </div>
            <ul
              className={`${styles.scrollHidden} mt-5 flex flex-none items-center gap-2 overflow-x-scroll`}>
              {homeCourseCategoryList.map((cate, idx) => {
                const isActive = courseCategory === cate.id
                return (
                  <li
                    key={cate.id}
                    ref={ref => (popularCateRef.current[idx] = ref)}
                    className={`${
                      isActive ? 'bg-[#1E1E1E] text-white' : 'border border-neutral'
                    } shrink-0 cursor-pointer rounded-full px-3 py-2 text-sm lg:text-base`}
                    onClick={() => onClickPopularCategory(cate.id, idx)}>
                    {cate.title}
                  </li>
                )
              })}
            </ul>
            <div className="mt-8">
              {isLoadingKDT && isDeferred ? (
                <ul
                  id="home"
                  className="grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-6">
                  {[1, 2, 3, 4].map(el => (
                    <KdtSchoolSkeleton key={`${el}_skeleton`} />
                  ))}
                </ul>
              ) : (
                <ul
                  id="home"
                  className="grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-6">
                  {showroomCourseList &&
                    showroomCourseList[0].courses.map(course => (
                      <CatalogKlassCard key={course.id} course={course} />
                    ))}
                </ul>
              )}
            </div>
          </section>
          {/* KDC */}
          <section className="space-y-2 lg:space-y-10">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between lg:items-end">
                <h3 className="text-2xl font-bold md:text-xl lg:text-[32px]">6주완성</h3>
                <Link href={'/onboarding'} as="/onboarding">
                  <a className="flex items-center">
                    <span className="mr-1 text-sm font-medium text-label-alternative lg:text-base">
                      자세히 보기
                    </span>
                    <ChevronRightIcon className="block w-4 text-label-alternative lg:hidden" />
                  </a>
                </Link>
              </div>
              <span className="text-sm text-label-neutral md:text-base">
                기초부터 탄탄히! 국비 지원으로 최대 두 가지 VOD 강의 수강 가능해요!
              </span>
            </div>
            {(isLoadingKDC && isDeferred) || showroomKDCList === undefined ? (
              <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-3 lg:gap-x-6 lg:gap-y-10">
                {[1, 2, 3].map(el => (
                  <KdcSchoolSkeleton key={`${el}_kdcSkeleton`} />
                ))}
              </div>
            ) : (
              <div className="">
                <KDCContentList kdcList={showroomKDCList[0].courses} />
              </div>
            )}
          </section>
          {!isEmpty(kdtShowroomList) && (
            <section className="space-y-5 md:space-y-6 lg:space-y-8">
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between lg:items-end">
                  <h3 className="text-2xl font-bold md:text-xl lg:text-[32px]">
                    국비지원 부트캠프
                  </h3>
                  <Link href={window.bootcampDomain} as={window.bootcampDomain}>
                    <a className="flex items-center" target="_blank">
                      <span className="mr-1 text-sm font-medium text-label-alternative lg:text-base">
                        자세히 보기
                      </span>
                      <ChevronRightIcon className="block w-4 text-label-alternative lg:hidden" />
                    </a>
                  </Link>
                </div>
                <span className="text-sm text-label-neutral md:text-base">
                  4-6개월 동안 IT 취업 및 커리어 전환을 위한 집중 교육을 받아보세요.
                </span>
              </div>
              <KDTTextBanner />
              {kdtShowroomList && (
                <ul
                  id="home"
                  className="grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-3 md:gap-x-5 lg:grid-cols-4 lg:gap-x-6">
                  {kdtShowroomList?.map(course => (
                    <KDTKlassCard course={course} key={course.id} />
                  ))}
                </ul>
              )}
            </section>
          )}
          {/* 모든 교육 경험 */}
          <section className="mb-20 lg:mb-40">
            <div className={'mx-auto mt-8 flex w-full flex-col lg:mt-10'}>
              <div className="flex flex-col flex-wrap justify-between gap-y-8 md:flex-row">
                {homeCTACardList.map(({ title, category, url, bgImg }) => (
                  <HomeCTACard
                    title={title}
                    category={category}
                    url={url}
                    key={`${title}_${url}`}
                    bgImg={bgImg}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </main>
      {isModalOpen && (
        <AIModal isOpen={isModalOpen} handleClose={onCloseModal} uuid={uuid} profile={profile} />
      )}
    </WithHeader>
  )
}

export default Home
