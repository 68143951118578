import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

interface ICurationHistoriesProps {
  uuid: string
  userId: number | null
  aiCurationId: number
  question: string
  depth: number
  link: string
}

const useAiCurationHistories = () => {
  const queryClient = useQueryClient()

  const postAiCurationHistories = async (data: ICurationHistoriesProps) => {
    const result = await axios.post(
      `${window.apiEndpoint}/sqna/v1/ai-curation//${data.aiCurationId}/histories`,
      convertCaseList(data, ConvertType.SNAKE),
    )

    convertCaseList(result.data, ConvertType.CAMEL)
  }

  const mutation = useMutation(postAiCurationHistories, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['AiCurationHistories'])
    },
  })

  return mutation
}

export default useAiCurationHistories
