import Slider, { Settings } from 'react-slick'

import KDTTextBannerItem from './KDTTextBannerItem'

const KDTTextBanner = () => {
  const textBannerData = [
    {
      category: '사전알림',
      title: '기다리는 부트캠프가 있으신가요?\n 모집 시작하면 가장 먼저 알려드릴게요! 👀',
      goToTitle: '사전알림 신청하고 IT 가이드북 받기',
      url: `${window.bootcampDomain}/pre-notice`,
    },
    {
      category: '0원으로 수강하기',
      title: '국민내일배움카드 발급 가능 대상자라면\n 국비지원 교육을 무료로 받을 수 있어요!',
      goToTitle: '내일배움카드 발급 자격 확인하기',
      url: `${window.bootcampDomain}/nebeca`,
    },
  ]
  const settings: Settings = {
    dots: false,
    infinite: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    touchMove: false,
    arrows: false,
    centerMode: false,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: true,
  }
  return (
    <div className="">
      <Slider {...settings}>
        {textBannerData.map(textBanner => (
          <KDTTextBannerItem key={textBanner.title} textBanner={textBanner} />
        ))}
      </Slider>
    </div>
  )
}

export default KDTTextBanner
